export default {
  nav:{
    home:'Home',
    market:'Market',
    activitie:'Activities',
    whitelist:'Whitelist search',
    whitePaper:'White paper',
    zh:'zh',
    en:'en'
  },
  part1: {
    title: 'Boost the development of the NFT  Rush to the forefront of oversea market',
    content1: "Responding to Hong Kong's new policy, breaking through thedomestic market",
    content2: "Senior Web3 professional team, combined with multiple strategic partners",
    content3: "The main structure of the Hong Kong company, develop overseas markets"
  },
  part2: {
    title: 'Multi-chain aggregation mode, multiple incentives and deep co-construction',
    content1: "X-Meta.HK——Multi-chain aggregation NFT Market Place",
    content2: "NFT Minting, NFT secondary market, bidding auction, private transaction",
    content3: "Diversified incentive model, mission medal + MAS (points) + token ($XMC)",
    content4: "In-depth co-construction platform ecosystem"
  },
  part3: {
    title: '$XMC Token Core Innovative MAS Detonation Gameplay',
    content1: "As the core of X-Meta.HK, $XMC will be allocated in proportion to liquidity management, venture capital, Ecological pool, points exchange, pledge rewards, etc.",
    content2: "X-Meta.HK's innovative MAS system includes event tasks, mining methods, and transaction bonuses",
    content3: "Display of cumulative points progress of all users, etc.",
    content4: "create a new future to play between acquisition and consumption",
    tag:'MAS Loading. . .'
  },
  part4: {
    title: 'Security and transparency, Chinese power reshape the gameplay of Web3',
    content1: "Support multiple mainstream public chains, TP Pocket, Okx, and other types of wallets Efficiently process a large number of transactions",
    content2: "Provide smart contracts, secure and transparent transactions",
    content3: "Fully automated circulation mode, simplified transaction process, and improved NFT liquidity"
  },
  part5: {
    title: 'Multi-dimensional selection  Choose high-quality NFT projects',
    content1: "Strictly select the NFT project  and the issuance team",
    content2: "Multi-angle measurement of experience, operation and maintenance, project path, and professionalism",
    content3: "White list query system"
  },
  footer:{
    title:'Contact Us',
    privacy:'Privacy statement',
    terms:'Terms of Service'
  },
  play:{
    activities:'X-Meta.HK 官方活动合集',
    activitie1:{
      title:'活动名称活动名称活活动介绍活动介绍活动介绍 活 动介绍活动介绍活动介绍活 动介绍活动介绍活动',
      time:'2023-06-26',
      info:'活动名称活动名称活 活动介绍活动介绍活动介绍  动介绍活动介绍活动介绍活 动介绍活动介绍活动介活动名称活动名称活活动介绍活动介绍活动介绍 活 动介绍活动介绍活动介绍活 动介绍活动介绍活动介…活动名称活动名称活活动介绍活动介绍活动介绍 活 动介绍活动介绍活动介绍活 动介绍活动介绍活动介…活动名称活动名称活',
      know:'了解详情'
    }
  },
  white:{
    searchPlaceholder:'Please enter your wallet address',
    searchBtn:'search',
    searchResult:'search result',
    walletAddress:'wallet address',
    AssociatedItem:'Associated item',
    NoInformation:'No information'

  }
}
