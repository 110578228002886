
import request from '@/utils/request'


export function getWhiteList (data) {
  return request({
    url: '/platform/whiteList',
    method: 'post',
    data
  })
}