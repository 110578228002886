<template>
  <div class=" white">
    <div class="contain">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="form">
        <el-form-item prop="address">
           <el-input :placeholder="$t('white.searchPlaceholder')" v-model="ruleForm.address" class="inputSearch"  clearable>
            <el-button slot="append" class="search-btn"  @click="submitForm('ruleForm')">{{$t('white.searchBtn')}}</el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="search-result" v-if="searchInfoShow" >
        <div class="search-title" >
          <p class="title">{{$t('white.searchResult')}}</p>
          <p class="address">{{$t('white.walletAddress')}}：<span class="green">{{ruleForm.address}}</span></p>
        </div>
        <div v-if="data.length" class="result">
          <p v-for='(item,index) in data' :key="index" class="item">{{$t('white.AssociatedItem')}}{{index+1}}：<span class="green">{{`${item.genesis_name}（${item.quantity}个）`}}</span></p>
        </div>
        <p v-if="! data.length" >- - {{$t('white.NoInformation')}} - - </p>
      </div>
    </div>
   
  </div>
</template>

<script> 
import {getWhiteList} from '@/api/white.js'
export default {
  name:'whiteList',
  data(){
    return {
      ruleForm:{
         address:""
      },
      data:[],
      // data:[{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"},{"genesis_name":"Rebel eggs"}],
      searchInfoShow:false,
      rules:{
        address:[
          { required: true, message: 'required', trigger: ['blur','change'] }
        ]
      }
    }
  },
  watch:{
     'ruleForm.address': function(newValue, oldValue) {
      if(newValue !== oldValue){
        this.data = []
        this.searchInfoShow = false
      }
    }
  },
  methods:{
     submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.getWhiteList()
          } else {
            // this.$message.error('请输入钱包地址！')
            return false;
          }
        });
      },
      async getWhiteList(){
        try {
          const res = await getWhiteList({address:this.ruleForm.address})
          console.log('getWhiteList', res)
          console.log(JSON.stringify(res.data));
          // 保存数据
          if(res.status == 200){
            this.$nextTick(() => {
               this.data = res.data.data ?  res.data.data :[]
                this.searchInfoShow = true
            })
           
          }
        } catch (err) {
          console.log('getWhiteList', err)
        }
      },
  }
}
</script>

<style lang='less' scoped>
  .white {
    background: url('../../assets/image/white/whitebg.jpg') no-repeat center top;
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-attachment: fixed; /* 固定背景图 */
    overflow: auto;
    // .form {
    //   width: 80%;
    //    display: flex;
    //   justify-content: center;
    // }
    .inputSearch {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      margin-top: 220px;
      height: 70px;
      border-radius: 5%;
      width: 80%;
       border: none;
      // display: flex;
      // justify-content: center;
      /deep/ .el-input__inner {
        height: 100%;
         border: none !important;
         font-size: 22px;
      }
     
    }
     /deep/ .el-form-item__error {
        color: #00ecd3;
        font-size: 16px;
        padding-top: 10px;
        left: 10%;
      }
    .search-btn {
      background-color: #00ecd3;
      color: #fff;
      height: 100%;
      width: 120px;
      font-size: 22px;
      font-weight: 700;
    }
    .search-result {
      color: #fff;
      font-size: 26px;
      text-align: center;
      margin-top: 120px;
      width: 100%;
      // line-height: 35px;
      .title{
        font-size: 30px;
      }
      .address {
        padding:  20px 0 30px;
      }
      .green {
        color: #00ecd3;
      }
      .result{
        width: 100%;
        display: flex;
        justify-content: space-between;
        text-align: left;
        font-size: 24px;
        flex-wrap: wrap;
        .item {
          width:50%;
          margin: 5px 0;
        }
      }

    }
  }
</style>