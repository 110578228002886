<template>
  <div>
     <!-- 轮播图 -->
    <el-carousel height='100vh' class="banner">
        <el-carousel-item class="banner-item">
            <a href="#" target="_blank">
              <img src="@/assets/image/play/banner/banner1.png">
            </a>
        </el-carousel-item>
          <el-carousel-item class="banner-item">
            <a href="#" target="_blank">
              <img src="@/assets/image/home/banner1.png">
            </a>
        </el-carousel-item>
          <el-carousel-item class="banner-item">
            <a href="#" target="_blank">
              <img src="@/assets/image/home/banner1.png">
            </a>
        </el-carousel-item>
    </el-carousel>
     <div class="page">
      <div class="contain">
        <div class="top">
          <div class="title">{{$t('play.activities')}}</div>
          <div class="line"></div>
        </div>
      <div class="bottom">
          <div class="activities">
            <div class="activitie" v-for="index in 9" :key="index">
              <div class="act-titel">{{$t('play.activitie1.title')}}</div>
              <div class="act-time">{{$t('play.activitie1.time')}}</div>
              <div class="act-info">{{$t('play.activitie1.info')}}</div>
              <div class="act-know">{{$t('play.activitie1.know')}} <img src="@/assets/image/play/jiantou.png" alt="" class="know-jt"></div>
            </div>
        </div>
        <el-pagination
          class="custom-pagination"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="totalItems"
          @current-change="handleCurrentPageChange">
        </el-pagination>
      </div>
      </div>
     </div>
    <!-- 联系我们 -->
     <div class="page footer">
      <div class="contain">
        <div class="top">
          <div class="title">{{$t('footer.title')}}</div>
          <div class="line"></div>
        </div>
        <div class="footer-codes">
          <div class="code">
            <div  class="code-img">
              <img src="@/assets/image/home/footerCodes/tuite.png" alt="">
            </div>
            <p class="code-title">推特</p>
          </div>
          <div class="code">
            <div  class="code-img">
              <img src="@/assets/image/home/footerCodes/dc.png" alt="">
            </div>
            <p class="code-title">DC</p>
          </div>
          <div class="code">
            <div  class="code-img">
              <img src="@/assets/image/home/footerCodes/weibo.png" alt="">
            </div>
            <p class="code-title">微博</p>
          </div>
          <div class="code">
            <div>
                <img src="@/assets/image/home/footerCodes/qq.png" alt="">
            </div>
            <p>qq社群</p>
          </div>
      </div>
      <div class="footer-line"></div>
      <div class="footer-privacy">
        <p><a href="#">{{$t('footer.privacy')}}</a><a href="#">{{$t('footer.terms')}}</a></p>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'play',
  data(){
    return{
      currentPage: 1,
      pageSize: 9,
      totalItems: 269
    }
  },
  methods:{
     handleCurrentPageChange(page) {
      this.currentPage = page;
      // 处理页码改变的逻辑
      console.log(page);
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
    /deep/ .el-carousel__arrow{
      display: none
    }
      /deep/.el-carousel__indicator--horizontal .el-carousel__button {
      width: 15px;
      height: 15px;
      background: #888;
      // border: 1px solid #ffffff;
      border-radius: 50%;
      // opacity: 0.8;
      border: none !important;
    }
    /deep/.el-carousel__indicator--horizontal.is-active .el-carousel__button {
      width: 43px;
      height: 15px;
      background: #777;
      border-radius: 5px;
      border: none !important;
      opacity: 1;
    }
    /deep/.el-carousel__indicators--horizontal {
      // width: 100vw;
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%)
    }
    .banner-item{
    img{
      width: 100%;
      height: 100%;
    }
}

}
.page{
  width: 100%;
  // height: 919px;
  // background-color: #012022;
  background-color: #101615;
  padding-top: 140px ;
  .top{
    width: 100%;
     text-align: center;
     .title {
        color: #fff;
        font-size: 50px;
        font-weight: 700;
        padding-bottom: 40px;
      }
      .line {
        width: 303px;
        height: 4px;
        background-color:#00ECD3 ;
        margin: 0 auto;
        margin-bottom: 40px;
      }
  }
}
.activities {
  margin-top: 60px ;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: rgba(255,255,255,.9);
  font-size: 22px;
  .activitie{
    width: 373px;
    height: 366px;
    border-radius: 30px;
    background-color: rgba(107, 107, 107, .2);
    padding: 38px 25px  0 32px;
    margin-bottom: 40px ;
    // opacity: 0.2;
    cursor: pointer;
    .act-titel {
      font-size: 28px;
      white-space: nowrap; /* 禁止文本换行 */
      overflow: hidden; /* 隐藏溢出部分 */
      text-overflow: ellipsis; /* 超出部分显示省略号 */
    }
    .act-time{
      color: #ADACAC;
      margin: 10px 0 15px;
    }
    .act-info{
      line-height: 40px;
      display: -webkit-box; /* 使用弹性盒子模型 */
      -webkit-line-clamp: 3; /* 设置最大显示行数为3 */
      -webkit-box-orient: vertical; /* 文本垂直方向排列 */
      overflow: hidden; /* 隐藏溢出部分 */
      text-overflow: ellipsis; /* 超出部分显示省略号 */
    }
    .act-know{
      margin: 35px 0 0 185px;
      color: #ADACAC;
      .know-jt {
        vertical-align: middle;
        margin-left: 10px;
        margin-top: -2px;
      }
    }
  }
  .activitie:hover {
    border: 1px solid #03d5c0 ;
    background-color:rgba(0, 236, 211, .1);
    .act-titel {
      color: #00ECD3;
    }
  }
}
.el-pagination {
  color: #C5C5C5 ;
  font-size: 30px;
}
/deep/ .el-pagination .btn-prev {
  background: none !important;
     width: 62px;
    height: 62px;
    border: 2px solid #C5C5C5 ;
    background-size: 62px;
    border-radius: 10px;
}
/deep/ .el-pagination .btn-next {
  background: none !important;
     width: 62px;
    height: 62px;
    border: 2px solid #C5C5C5 ;
    background-size: 62px;
     color: #C5C5C5;
     border-radius: 10px;
}
/deep/ .el-pager li{
    width: 62px;
    height: 62px;
    border: 2px solid #C5C5C5 ;
    background: none;
    font-size: 30px ;
    color: #C5C5C5;
    // display: flex;
    // align-items: cen;
    margin-right: 25px;
    line-height: 62px;
    border-radius: 10px;
    font-weight: 400;
}
/deep/.el-pager li:nth-child(1){
  margin-left: 25px;
}
.custom-pagination{
  margin:  0  auto;
  padding-bottom: 120px;
  padding-top: 60px;
  display: flex;
  justify-content: center;
}

/deep/.el-pager li.active+li {
    border-left: 2px solid #C5C5C5;
}
/deep/.el-pager li:hover {
    color: #00ECD3;
}
/deep/.el-pager li.active {
   width: 62px;
    height: 62px;
    border: 2px solid #00ECD3 ;
    color: #00ECD3;

    
}
/deep/.el-pagination button:disabled {
  color: #C5C5C5 !important;
    border: 2px solid #C5C5C5 !important;

}
/deep/.el-pagination button:hover {
    color: #00ECD3;
     border: 2px solid #00ECD3 ;
}
/deep/.el-pagination .btn-next .el-icon, .el-pagination .btn-prev .el-icon {
  font-size: 26px;
}
/deep/ .el-pagination .btn-prev .el-icon {
    font-size: 26px;
    // font-weight: 700;
}

/deep/ .el-pagination .btn-prev{
  color: #C5C5C5;
}
/deep/ .el-pager li.btn-quicknext, .el-pager li.btn-quickprev {
    line-height: 62px !important;
    color: #C5C5C5 !important;
}
/deep/.el-pager li.btn-quickprev {
    line-height: 62px !important;
    color: #C5C5C5 !important;
}
.footer{
    background-color: black;
  padding-bottom: 31px;
    .footer-codes{
      margin-top: 67px;
      display: flex;
      justify-content: space-between;
      font-size: 30px;
        color: #fff;
        text-align: center;
        
      .code-img {
        padding-right: 52px ;
        border-right: 2px solid #00ECD3;
      }
      .code-title {
        margin-left: -42px;
        
      }
      

    }
    .footer-line{
      width: 100%;
      height: 2px;
      background-color: #101615;
      margin-top: 72px;
      margin-bottom: 30px;
    }
    .footer-privacy{
      text-align: center;
    
      a {
        font-size: 20px;
        color: #eee;
        margin-right: 20px;
      }
    }
  }
</style>