// request.js
import axios from 'axios'

// 创建一个axios实例
const service = axios.create({
  baseURL: 'https://api.x-meta.hk/api',
 
})

// 暴露axios实例
export default service