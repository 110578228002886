<template>
  <div>
      <header>
        <div class="contain nav">
           <div  @click="$router.push('/')" class="log">
              <img alt="Image placeholder" src="@/assets/image/log.png" >
          </div>
          <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              text-color="#999"
              background-color='#101615'	
              active-text-color="#fff"
              router
          >
              <el-menu-item index="/home">{{$t('nav.home')}}</el-menu-item>
              <el-menu-item @click='handleItemClick("https://h5.x-meta.hk/#/")'>{{$t('nav.market')}}</el-menu-item>
              <!-- <el-menu-item index="/play">{{$t('nav.activitie')}}</el-menu-item> -->
              <el-menu-item @click='handleItemClick("https://imh93dkr.eqxiu.cn/v/167282?cc=4pjGC0kX&toPage=727112&share_level=5&from_user=1d88b32e-b1dd-401f-b007-eb493db79e73&from_id=3fb8c04d-5&shareUserId=undefined")'>{{$t('nav.activitie')}}</el-menu-item>
              <el-menu-item index="/whiteList">{{$t('nav.whitelist')}}</el-menu-item>
              <el-menu-item  @click='openPDF'>{{$t('nav.whitePaper')}}</el-menu-item>
              <el-submenu index='subment'>
                <template slot="title">
                  <img src="@/assets/image/diqiu.png" alt="">
                </template>
                <el-menu-item  :disabled="'zh'=== $i18n.locale " @click="$i18n.locale = 'zh'" style="font-size: 20px;margin-bottom:10px">{{$t('nav.zh')}}</el-menu-item>
                <el-menu-item :disabled="'en'=== $i18n.locale "  @click="$i18n.locale = 'en'" style="font-size: 20px;margin-bottom:10px">{{$t('nav.en')}}</el-menu-item>
              </el-submenu>
            </el-menu>
        </div>
      </header>
     <router-view></router-view>
     <!-- <iframe :src="pdfSrc" width="100%" height="800px" style="position: absolute;top:0;left:0;z-index: 999"></iframe> -->
  </div>
</template>

<script>
export default {
  name: 'layout',
  data(){
    return {
      activeIndex:'/home',
      pdfSrc:'/pdf/whitePaper_zh.pdf'
    }
  },
  mounted(){
    this.activeIndex = '/' + this.$route.name;
  },
  methods:{
    handleItemClick(url){
      // console.log(index);
      window.location.href = url 
    },
    openPDF(){
    //  this.pdfSrc =  this.$i18n.locale == 'zh' ? '/pdf/whitePaper_zh.pdf' : '/pdf/whitePaper_en.pdf';
     this.$i18n.locale == 'zh' ? window.open('/pdf/whitePaper_zh.pdf') : window.open('/pdf/whitePaper_en.pdf') 
    }
    // handleSelect(key, keyPath){
    //   console.log(key, keyPath);
    //   this.activeIndex = key
    // },
  }
}
</script>

<style lang="less" scoped>
header{
  background-color: #101615;
  width: 100%;
   height: 80px;
    line-height: 80px;
    box-sizing: border-box;
    // font-weight:500;
    // position: -webkit-sticky;
    position: absolute;
    top: 0;
    z-index: 999;
    // box-shadow: 0 1px 10px 2px #012022 ;
    .log{
      img{
        vertical-align: middle;
      }
      
    }
  .nav{
    display: flex;
    // justify-content: flex-end;
    justify-content: space-between;
    
    .log{
      cursor: pointer;
    }
  }
  .el-menu-item {
    font-size: 20px ;
    line-height: 80px;
    height: 80px;
  }
  // .el-submenu{
  //   font-size: 22px ;
  //   line-height: 90px;
  // }
  .el-menu.el-menu--horizontal{
    border-bottom: none;
  }
  .el-menu--horizontal>.el-submenu .el-submenu__title {
    border-bottom: none !important;
  }
 /deep/ .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border-bottom: none !important;
  }
    /deep/.el-menu .el-menu-item:hover{
        outline: 0 !important;
        color: #fff !important;
        background-color: #101615 !important;
    }
    /deep/.el-menu .el-menu-item.is-active {
        color: #fff !important;
        background: #101615 !important;
        // border-bottom: 2px solid red;
    }
    .el-menu--horizontal>.el-menu-item.is-active{
      // border-bottom: none;
      border-bottom: 4px solid #2FEFD2  !important;
    }
  .el-submenu /deep/.el-submenu__ti tle:hover {
    color: #fff !important;
    background: #101615 !important;
  }
 /deep/ .el-submenu__title{
    line-height: 80px !important;
    height: 80px !important;
  }
  /deep/ .el-submenu__title i {
    color: #999 !important;
    font-size: 20px;
 }

}
// .contain {
//   background-color: rgba(0,0,0,.2);
// }

</style>