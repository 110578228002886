export default {
  nav:{
    home:'首页',
    market:'市场',
    activitie:'活动',
    whitelist:'白名单查询',
    whitePaper:'白皮书',
    zh:'中文',
    en:'英文'
  },
  part1: {
    title: '助推NFT行业发展 抢登市场出海前沿',
    content1: "响应香港新政，突破内地市场",
    content2: "资深Web3专业团队，联合多个战略合作伙伴",
    content3: "香港公司主体架构 乘风出海"
  },
  part2: {
    title: '多链聚合模式  多元激励深度共建',
    content1: "X-Meta.HK——多链聚合NFT Market Place",
    content2: "项目铸造发售、NFT二级市场、竞价拍卖、私人交易",
    content3: "多元化激励模式，任务勋章+MAS（积分）+代币（$XMC）",
    content4: "深度共建平台生态系统"
  },
  part3: {
    title: '$XMC代币核心 创新MAS引爆玩法',
    content1: "$XMC作为X-Meta.HK的核心，将按比例分配于流动性管理、风险投资、生态池、积分兑换、质押奖励等",
    content2: "X-Meta.HK的创新MAS体系，包含活动任务、挖掘玩法、交易加成",
    content3: "全体用户累计积分进度展示等",
    content4: "助力X-Meta.HK生态焕活，在获取和消耗之间萌生超多玩法",
    tag:'MAS值正在加载中. . .'
  },
  part4: {
    title: '公链支持安全透明  华人力量重塑Web3玩法',
    content1: "支持多主流公链，TP Pocket、欧易Web3等多类钱包，大量交易高效处理",
    content2: "提供智能合约，交易安全透明，避免“老鼠仓”等暗箱操作",
    content3: "全自动化流转方式，简化交易流程，提升NFT流动性"
  },
  part5: {
    title: ' 多维度评选 严选优质NFT项目',
    content1: "严选NFT项目方，对项目发行团队",
    content2: "经验、运维、项目路径、专业性多角度衡量",
    content3: "并实行项目白名单可查询措施，一键查询"
  },
  footer:{
    title:'联系我们',
    privacy:'隐私声明',
    terms:'服务条款'
  },
  play:{
    activities:'X-Meta.HK 官方活动合集',
    activitie1:{
      title:'活动名称活动名称活活动介绍活动介绍活动介绍 活 动介绍活动介绍活动介绍活 动介绍活动介绍活动',
      time:'2023-06-26',
      info:'活动名称活动名称活 活动介绍活动介绍活动介绍  动介绍活动介绍活动介绍活 动介绍活动介绍活动介活动名称活动名称活活动介绍活动介绍活动介绍 活 动介绍活动介绍活动介绍活 动介绍活动介绍活动介…活动名称活动名称活活动介绍活动介绍活动介绍 活 动介绍活动介绍活动介绍活 动介绍活动介绍活动介…活动名称活动名称活',
      know:'了解详情'
    }
  },
  white:{
    searchPlaceholder:'请输入钱包地址',
    searchBtn:'搜索',
    searchResult:'查询结果',
    walletAddress:'钱包地址',
    AssociatedItem:'关联项目',
    NoInformation:'暂无信息'

  }
}
