import Vue from 'vue'
import VueRouter from 'vue-router'

import home from '@/views/home/index.vue'
import whiteList from '@/views/whiteList/index.vue'
import layout from '@/layout/index.vue'
import play from '@/views/play/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: home
      },
      {
        path: '/whiteList',
        name: 'whiteList',
        component: whiteList
      },
      // {
      //   path: '/play',
      //   name: 'play',
      //   component: play
      // }
    ]
  },
  
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // 每次导航开始时，将滚动位置恢复到顶部（纵向滚动位置为0）
  window.scrollTo(0, 0);
  next();
});

export default router
