<template>
  <div>
    <!-- 轮播图 -->
    <el-carousel height='100vh' class="banner">
      <!-- <el-carousel-item class="banner-item">
            <a href="https://imh93dkr.eqxiu.cn/v/179942?cc=BKpMxdLV" target="_blank">
              <img src="@/assets/image/home/banner2_20230811.jpg">
            </a>
        </el-carousel-item> -->
        <el-carousel-item class="banner-item">
            <a href="https://imh93dkr.eqxiu.cn/v/166800?cc=QN5Za3PN" target="_blank">
              <img src="@/assets/image/home/banner1_20230718150213.jpg">
            </a>
        </el-carousel-item>
    </el-carousel>
    <!-- 内容 -->
    <div class="page">
      <div class="contain">
        <div class="top">
          <div class="title">{{$t('part1.title')}}</div>
          <div class="line"></div>
        </div>
      <div class="center">
        <div class="page1-left">
          <p>{{$t('part1.content1')}}</p>
          <p>{{$t('part1.content2')}}</p>
          <p>{{$t('part1.content3')}}</p>
        </div>
        <div class="page1-right">
          <img src="@/assets/image/home/page1.png" alt="">
        </div>
      </div>
      </div>
    </div>
    <div class="page page2">
      <div class="contain">
        <div class="top">
          <div class="title">{{$t('part2.title')}}</div>
          <div class="line"></div>
        </div>
      <div class="center">
        <div class="page2-left">
          <el-carousel :interval="4000" type="card" height="427px" :indicator="false" arrow='never'>
            <el-carousel-item>
              <img src="@/assets/image/home/page2ban1.png" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="@/assets/image/home/page2ban2.png" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="@/assets/image/home/page2ban3.png" alt="">
            </el-carousel-item>
          </el-carousel>
          
        </div>
        <div class="page2-right">
          <p>{{$t('part2.content1')}}</p>
          <p>{{$t('part2.content2')}}</p>
          <p>{{$t('part2.content3')}}</p>
          <p>{{$t('part2.content4')}}</p>
        </div>
      </div>
      </div>
    </div>
    <div class="page">
      <div class="contain">
        <div class="top">
          <div class="title">{{$t('part3.title')}}</div>
          <div class="line"></div>
        </div>
        <!-- 进度条 -->
        <div class="progress">
          <div class="progress-crlic"><img src="@/assets/image/home/prggress_crol.png" alt=""></div>
          <div class="progress-bar">
            <img src="@/assets/image/home/pargress-bar.png" alt="">
            <div class="progress-color"></div>
            <img src="@/assets/image/home/pargress-tag.png" alt="" class="progress-tag">
            <span class="progress-text">{{$t('part3.tag')}}</span>
          </div>
        </div>
      <div class="center">
        <div class="page3-left">
          <p><img src="@/assets/image/home/pag3-dian.png" alt="">{{$t('part3.content1')}}</p>
          <p><img src="@/assets/image/home/pag3-dian.png" alt="">{{$t('part3.content2')}}</p>
          <p><img src="@/assets/image/home/pag3-dian.png" alt="">{{$t('part3.content3')}}</p>
          <p><img src="@/assets/image/home/pag3-dian.png" alt="">{{$t('part3.content4')}}</p>
        </div>
        <div class="page3-right">
          <img src="@/assets/image/home/bgpage3.png" alt="">
        </div>
      </div>
      </div>
    </div>
    <div class="page page4">
      <div class="contain">
        <div class="top">
          <div class="title">{{$t('part4.title')}}</div>
          <div class="line"></div>
        </div>
      <div >
        <div class="page4-top">
          <p>{{$t('part4.content1')}}</p>
          <p>{{$t('part4.content2')}}</p>
          <p>{{$t('part4.content3')}}</p>
        </div>
        <div class="page4-bottom">
          <img src="@/assets/image/home/page4.png" alt="">
        </div>
      </div>
      </div>
    </div>
     <div class="page page5">
      <div class="contain">
        <div class="top">
          <div class="title">{{$t('part5.title')}}</div>
          <div class="line"></div>
        </div>
      <div >
        <div class="page4-top">
          <p>{{$t('part5.content1')}}</p>
          <p>{{$t('part5.content2')}}</p>
          <p>{{$t('part5.content3')}}</p>
        </div>
        <div class="page4-bottom">
          <div>
            <img src="@/assets/image/home/page5-p1.png" alt="">
            <span>Crazy Fiction</span>
          </div>
           <div>
            <img src="@/assets/image/home/page5-p2.png" alt="">
            <span>Bluemoon</span>
          </div>
           <div>
            <img src="@/assets/image/home/page5-p3.png" alt="">
            <span>Rebel Ducks</span>
          </div>
        </div>
      </div>
      </div>
    </div>
    <!-- 联系我们 -->
     <div class="page footer">
      <div class="contain">
        <div class="top">
          <div class="title">{{$t('footer.title')}}</div>
          <div class="line"></div>
        </div>
        <div class="footer-codes">
          <div class="code">
            <div  class="code-img">
              <img src="@/assets/image/home/footerCodes/tuite.png" alt="">
            </div>
            <p class="code-title">推特</p>
          </div>
          <div class="code">
            <div  class="code-img">
              <img src="@/assets/image/home/footerCodes/dc.png" alt="">
            </div>
            <p class="code-title">DC</p>
          </div>
          <div class="code">
            <div  class="code-img">
              <img src="@/assets/image/home/footerCodes/weibo.png" alt="">
            </div>
            <p class="code-title">微博</p>
          </div>
          <div class="code">
            <div>
                <img src="@/assets/image/home/footerCodes/qq.png" alt="">
            </div>
            <p>qq社群</p>
          </div>
      </div>
      <div class="footer-line"></div>
      <div class="footer-privacy">
        <p><span @click="openPrivacy">{{$t('footer.privacy')}}</span><span  @click="openTerms">{{$t('footer.terms')}}</span></p>
      </div>
      </div>
    </div>
    <el-drawer :visible.sync="privacyVisible"  direction='btt' size="80%" @opened="scrollToTop('privacyDrawer')" v-if="privacyVisible">
      <div ref="privacyContainer" class="privacy-container" v-html="$t('privacyPolicy')" ></div>
    </el-drawer>
   <el-drawer :visible.sync="termsVisible"  direction='btt' size="80%" @opened="scrollToTop('termsDrawer')" v-if="termsVisible">
      <div ref="termsContainer" class="terms-container" v-html="$t('termsService')"></div>
    </el-drawer>
  </div>
</template>

<script>
// import mammoth from 'mammoth';
// import TermsZH from '@/docx/Terms of service（zh）.docx'; 
// import TermsEN from '@/docx/Terms of service（English）.docx'; 
// import privacyZH from '@/docx/Privacy Policy（zh）.docx'; 
// import privacyEN from '@/docx/Privacy Policy（English）.docx'; 
// import TermsZH from '/public/static/Terms of service（zh）.docx'; 
// import TermsEN from '/public/static/Terms of service（English）.docx'; 
// import privacyZH from '/public/static/Privacy Policy（zh）.docx'; 
// import privacyEN from '/public/static/Privacy Policy（English）.docx'; 
export default {
  name: 'home',
  data(){
    return {
      activeIndex:'/',
      termsVisible:false,
      privacyVisible:false,
      renderedDocx: ''
    }
  },
//   computed: {
//   formattedPrivacyContent() {
//     const privacyContent = this.renderedDocx
//     const formattedContent = privacyContent.replace(/\n/g, "<br>");
//     return formattedContent;
//   }
// },

  created(){
    console.log(this.$i18n.locale);
    
  },
  // mounted() {
  //   this.$nextTick(()=> {
  //      this.initializeViewer();
  //   })
   
  // },
  methods:{
    handleSelect(key, keyPath){
      console.log(key, keyPath);
    },
    async openPrivacy() {
      this.privacyVisible = true;
      // try {
      //   const blob = await this.fetchFileAsBlob(this.$i18n.locale == 'zh' ? privacyZH : privacyEN);
      //   const result = await this.convertDocxToHtml(blob);
      //   this.renderedDocx = result.value;
      //   console.log(this.formattedPrivacyContent);
      // } catch (error) {
      //   console.error('Failed to convert .docx to HTML:', error);
      // }
    },
     async openTerms() {
      this.termsVisible = true;
      // try {
      //   const blob = await this.fetchFileAsBlob(this.$i18n.locale == 'zh' ? TermsZH : TermsEN);
      //   const result = await this.convertDocxToHtml(blob);
      //   this.renderedDocx = result.value;
      //   console.log(this.formattedPrivacyContent);
      // } catch (error) {
      //   console.error('Failed to convert .docx to HTML:', error);
      // }
    },
    // fetchFileAsBlob(file) {
    //   return new Promise((resolve, reject) => {
    //     fetch(file)
    //       .then(response => response.blob())
    //       .then(blob => resolve(blob))
    //       .catch(error => reject(error));
    //   });
    // },
    // convertDocxToHtml(blob) {
    //   return new Promise((resolve, reject) => {
    //     const fileReader = new FileReader();
    //     fileReader.onload = function(e) {
    //       const arrayBuffer = e.target.result;
    //       mammoth.extractRawText({ arrayBuffer: arrayBuffer })
    //         .then(result => {
    //           // // 使用mammoth.js将docx转换为HTML
    //           // const options = {
    //           //   styleMap: "p[style-name='Heading 1'] => h1:fresh"
    //           //   // 这里可以添加更多的样式映射
    //           // };
    //           // return mammoth.convertToHtml({ arrayBuffer: arrayBuffer }, options);
    //           // console.log(result);
    //           resolve(result);
    //         })
    //         .catch(error => {
    //           reject(error);
    //         });
    //     };
    //     fileReader.readAsArrayBuffer(blob);
    //   });
    // },
    
  scrollToTop(refName) {
      const drawerElement = this.$refs[refName].$el;
      drawerElement.querySelector('.el-drawer__body').scrollTo(0, 0);
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  /deep/ .el-carousel__arrow{
    display: none
  }
    /deep/.el-carousel__indicator--horizontal .el-carousel__button {
    width: 15px;
    height: 15px;
    background: #888;
    // border: 1px solid #ffffff;
    border-radius: 50%;
    // opacity: 0.8;
    border: none !important;
  }
  /deep/.el-carousel__indicator--horizontal.is-active .el-carousel__button {
    width: 43px;
    height: 15px;
    background: #777;
    border-radius: 5px;
    border: none !important;
    opacity: 1;
  }
  /deep/.el-carousel__indicators--horizontal {
    // width: 100vw;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%)
  }
  .banner-item{
  img{
    width: 100%;
    height: 100%;
  }
}
}

.page{
  width: 100%;
  // height: 919px;
  // background-color: #012022;
  background-color: #101615;
  padding-top: 153px ;
  .top{
    width: 100%;
     text-align: center;
     .title {
        color: #fff;
        font-size: 50px;
        font-weight: 700;
        padding-bottom: 40px;
      }
      .line {
        width: 303px;
        height: 4px;
        background-color:#00ECD3 ;
        margin: 0 auto;
        margin-bottom: 40px;
      }
  }
  .center {
    display: flex;
    justify-content: space-between;
    color: rgba(255,255,255,.9);
    font-size: 32px;
    .page1-left {
      width: 100%;
      padding-top: 77px ;
      p{
        margin-bottom:20px ;
      }
    }
    .page1-right {
      margin-left: 85px;
      margin-bottom: 190px;
    }
    
    


  }
  
}
.page2{
  // background: ;
  background-image: url('../../assets/image/home/bgpage2.png') ;
  background-repeat: no-repeat;
  background-size: 100% 1022px;
  .page2-left{
    width:440px !important;
    padding-top: 50px;
    margin-bottom: 240px;
    // .el-carousel__container{
    //   
    // }
    // .custom-carousel .el-carousel__item {
    //     transition: transform 0.3s ease-out;
    //   }

    //   .custom-carousel .el-carousel__item.is-active {
    //     transform: translateY(-10px) !important;
    //   }
    img {
      width:215px;
      height: auto;
    }
  /deep/  .el-carousel__mask{
      border-radius: 30px;
     
    }
  }
  .page2-right{
    width: 680px;
      // margin-left: 90px;
       padding-top: 60px;
      p {
        margin-bottom: 20px;
      }
    }
  /deep/ .el-carousel__indicators--outside{
    display: none;
  }
 /deep/ .el-carousel__mask{
    background-color: rgba(0,0,0,.4);
    opacity: 1;
  }
}

.progress-bar{
  margin-top: 20px;
  position: relative;
  .progress-tag{
    position: absolute;
    left: 225px;
    top: -10px;
    // transform: translateX(100px);
   animation: slideAnimation 4s linear infinite;
  }
    @keyframes slideAnimation {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(20px);
    }
    100% {
      transform: translateX(0);
    }
  }
  .progress-color{
    position: absolute;
    left: 5px;
    top: 4px;
    width: 265px;
    height: 54px;
    background: linear-gradient(70deg, #00E2D6, #0083F1);
    // z-index: 3;
    border-radius: 30px;
  }
  .progress-text {
    position: absolute;
    // left: ;
    right: 120px;
    top: 15px;
    color: #ddd;
    font-size: 24px;
  }
}

.page3-left{
  margin-top: 60px;
  p {
    margin-bottom: 20px;
  }
  img {
    margin-right: 20px;
    // margin-top: 5px;
    vertical-align:bottom;
  }
}
.page3-right{
  position: relative;
  top: -120px;
  margin-left: 90px;
  // margin-bottom: 20px;
}
.page4{
   background-image: url('../../assets/image/home/bgpage4.png') ;
  background-repeat: no-repeat;
  background-size: 100% 1500px;
  // padding-top: 10px;
  .top{
    margin-top: 20px;
  }
  .line{
    margin-bottom: 60px !important;
  }
    .page4-top{
    color: rgba(255,255,255,.9);
    font-size: 32px;
    text-align: center;
    // margin-top: 32px;
    p {
      margin-bottom: 20px;
    }

  }
  .page4-bottom{
    margin-top: 60px;
    img{
      margin-bottom: 100px;
    }
    
  }

}
.page5{
 
  .line{
    margin-bottom: 50px !important;
  }
    .page4-top{
    color: rgba(255,255,255,.9);
    font-size: 32px;
    text-align: center;
    margin-top: 32px;
    p {
      margin-bottom: 20px;
    }

  }
  .page4-bottom{
    margin-top: 50px;
    
     display: flex;
     justify-content: space-evenly;
    div{
      position: relative;
       margin-bottom: 150px;
    }
    span {
      position: absolute;
      left: 29px;
      bottom: 31px;
      color: #fff;
      font-size: 36px;
    }
    
  }

}
.footer{
  background-color: black;
 padding-bottom: 31px;
  .footer-codes{
    margin-top: 67px;
    display: flex;
    justify-content: space-between;
    font-size: 30px;
      color: #fff;
      text-align: center;
      
    .code-img {
      padding-right: 52px ;
      border-right: 2px solid #00ECD3;
    }
    .code-title {
      margin-left: -42px;
      
    }
    

  }
  .footer-line{
    width: 100%;
    height: 2px;
    background-color: #101615;
     margin-top: 72px;
     margin-bottom: 30px;
  }
  .footer-privacy{
    text-align: center;
   
    span {
      font-size: 20px;
      color: #eee;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  
}
/deep/.el-drawer.btt{
    background-color: #101615;
    padding: 0 0 0 20px;
  }
  /deep/ .el-drawer {
    color: rgba(255,255,255,.9) !important;

  }

</style>